import Slider from '@babylon/ui-kit-structures/components/others/slider';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

import { AverageStars } from '../renderStars/styled';

export const Carousel = styled(Slider)`
	width: 90%;
	padding-left: 10px;
	.splide {
		position: relative;
		width: 100%;
		padding: 0 23px;
		overflow: hidden;
	}
`;
export const Wrapper = styled.div`
	${FlexMixin()}
`;

export const Average = styled.div`
	${FlexMixin({ align: 'center', direction: 'column' })}

	${AverageStars} {
		i {
			font-size: 28px;
		}
	}
`;

export const RatingText = styled.div`
	${FontMixin({ size: 'larger', height: 'larger', weight: 'medium' })}
	margin-bottom: 12px;
	margin: 16px 0;
`;

export const DateDiv = styled.div`
	${FontMixin({ size: 'small', height: 'large', weight: 'light' })}
	color: ${({ theme }) => theme.colors.grays.dark};
`;

export const Title = styled.p`
	${FontMixin({ size: 'medium', height: 'large', weight: 'medium' })}
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin: 8px 0 0;
`;
export const Text = styled.p`
	${FontMixin({ size: 'medium', height: 'large', weight: 'light' })}
	line-height: 16px;
	flex-grow: 1;
	margin: 8px 0;
`;

export const AverageText = styled.p`
	${FontMixin({ size: 'small', height: 'large', weight: 'light' })}
	margin: 8px 0;
`;

export const Name = styled.p`
	${FontMixin({ size: 'small', height: 'large', weight: 'light' })}
`;
