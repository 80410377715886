import Icon from '@babylon/ui-kit-base/components/icons/icon';
import contextBabylon from '@babylon/ui-kit-context';
import { formatDate } from '@babylon/ui-kit-helpers/date';
import { genKey } from '@babylon/ui-kit-helpers/keys';
import { Stars } from '@babylon/ui-kit-styles/common/mixins/stars.styled';
import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';

import * as S from './styled';
import type { RenderStarsProps } from './types';

const TOTAL_STARS = [1, 2, 3, 4, 5];

const getStarClassBasedOnAverage = (average: number) => {
	const roundAverage = Math.ceil((average % 1) * 100);

	if (roundAverage === 0) return 'empty';
	if (roundAverage <= 25) return 'star-25';
	if (roundAverage <= 50) return 'star-50';
	if (roundAverage <= 75) return 'star-75';
	return '';
};

export const RenderStars: FC<RenderStarsProps> = ({ average = 0, date, dateText, stars = 5 }) => {
	const { Language } = useContext(contextBabylon);
	const [displayDate, setDisplayDate] = useState('');

	useEffect(() => {
		if (date) formatDate(date, 'DD/MM/YYYY', Language).then(setDisplayDate).catch(console.error);
	}, [Language, date]);

	return (
		<S.AverageStars rate={stars}>
			<Stars>
				{TOTAL_STARS.map((val, index) => {
					let starClass = '';
					if (val === stars && average % 1 !== 0) {
						starClass = getStarClassBasedOnAverage(average);
					} else if (val > stars) {
						starClass = 'empty';
					}

					return (
						<div key={genKey(`${val}-${index}`)} className={starClass}>
							<Icon className='nico-star-trustedshops' />
						</div>
					);
				})}
			</Stars>
			<S.DateDiv>{dateText?.replace('[DATE]', displayDate)}</S.DateDiv>
		</S.AverageStars>
	);
};
